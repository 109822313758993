import { render, staticRenderFns } from "./comp_AutocompleteSearchField-ProductItem.vue?vue&type=template&id=658b63fa&"
import script from "./comp_AutocompleteSearchField-ProductItem.vue?vue&type=script&lang=js&"
export * from "./comp_AutocompleteSearchField-ProductItem.vue?vue&type=script&lang=js&"
import style0 from "./comp_AutocompleteSearchField-ProductItem.vue?vue&type=style&index=0&id=658b63fa&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
installComponents(component, {VListItem,VListItemAvatar,VListItemContent})
